import { render, staticRenderFns } from "./BillFileModal.vue?vue&type=template&id=397b7242&scoped=true"
import script from "./BillFileModal.vue?vue&type=script&lang=js"
export * from "./BillFileModal.vue?vue&type=script&lang=js"
import style0 from "./BillFileModal.vue?vue&type=style&index=0&id=397b7242&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397b7242",
  null
  
)

export default component.exports